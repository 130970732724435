import { toArray } from '@kontent-ai/utils';
import { Property } from 'csstype';
import React from 'react';
import styled, { css } from 'styled-components';
import {
  PolymorphicComponent,
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../@types/PolymorphicComponent.type.ts';
import { px } from '../../tokens/utils/utils.ts';

const getPercentage = (scale: number) => scale * 100;

export const columnWidthMap = {
  '1/2': getPercentage(1 / 2),
  '1/3': getPercentage(1 / 3),
  '1/7': getPercentage(1 / 7),
  '2/3': getPercentage(2 / 3),
  '1/4': getPercentage(1 / 4),
  '3/4': getPercentage(3 / 4),
  '1/5': getPercentage(1 / 5),
  '2/5': getPercentage(2 / 5),
  '3/5': getPercentage(3 / 5),
  '4/5': getPercentage(4 / 5),
};

type Width = keyof typeof columnWidthMap | 'content' | 'fit-content';
type FlexFactor = number | readonly [flexGrow: number, flexShrink: number];

type WidthOrFlexFactor =
  | Readonly<{
      flexFactor?: never;
      flexBasis?: never;
      minWidth?: number;
      maxWidth?: never;
      width?: Width;
    }>
  | Readonly<{
      flexFactor: FlexFactor;
      flexBasis?: Property.FlexBasis<number>;
      minWidth?: number;
      maxWidth?: number;
      width?: never;
    }>;

export type ColumnProps = WidthOrFlexFactor;

const noWidthStyles = css`
  flex: 1 1 auto;
  max-width: 100%;
`;
const contentWidthStyles = css`
  flex: 0 0 auto;
  max-width: 100%;
  width: auto;
`;
const fitContentWidthStyles = css`
  flex: 0 1 auto;
  max-width: 100%;
  width: auto;
`;

const getWidthStyles = ({
  $width,
  flexFactor,
  minWidth,
}: {
  readonly $width?: Width;
  readonly flexFactor?: FlexFactor;
  readonly minWidth?: number;
}) => {
  if (flexFactor) {
    return;
  }

  if ($width === 'content') {
    return css`
      ${contentWidthStyles};
      min-width: ${minWidth ? px(minWidth) : undefined};
    `;
  }

  if ($width === 'fit-content') {
    return css`
      ${fitContentWidthStyles};
      min-width: ${minWidth ? px(minWidth) : undefined};
    `;
  }

  if (!$width) {
    return css`
      ${noWidthStyles};
      min-width: ${minWidth ? px(minWidth) : undefined};
    `;
  }

  return css`
    flex: 0 0 ${columnWidthMap[$width]}%;
    ${minWidth === undefined ? '' : `min-width: ${px(minWidth)};`};
    max-width: ${columnWidthMap[$width]}%;
    width: ${columnWidthMap[$width]}%;
  `;
};

const getFlexFactorAttrs = ({
  flexFactor,
  flexBasis = 0,
  minWidth,
  maxWidth,
}: {
  readonly flexFactor?: FlexFactor;
  readonly flexBasis?: Property.FlexBasis<number>;
  readonly minWidth?: number;
  readonly maxWidth?: number;
}) => {
  if (flexFactor === undefined) {
    return;
  }
  const [flexGrow, flexShrink = flexGrow] = toArray<number>(flexFactor);
  return {
    style: {
      flexGrow,
      flexShrink,
      flexBasis,
      minWidth,
      maxWidth,
    },
  };
};

const StyledColumn = styled.div.attrs(getFlexFactorAttrs)`
  min-width: 0; /* important to prevent column overflow */
  padding-left: var(--column-spacing-X);

  ${getWidthStyles};
`;

export const Column = React.forwardRef(
  <TElement extends React.ElementType = 'div'>(
    {
      children,
      component,
      flexFactor,
      flexBasis,
      minWidth,
      maxWidth,
      width,
      ...otherProps
    }: React.PropsWithChildren<ColumnProps> & PolymorphicComponentProps<TElement>,
    ref: PolymorphicRef<TElement>,
  ) => (
    <StyledColumn
      as={component ?? 'div'}
      ref={ref}
      $width={width}
      flexFactor={flexFactor}
      flexBasis={flexBasis}
      minWidth={minWidth}
      maxWidth={maxWidth}
      {...otherProps}
    >
      {children}
    </StyledColumn>
  ),
) as PolymorphicComponent<ColumnProps>;

Column.displayName = 'Column';
